import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useDepartures } from '@/components/ui-logic/hooks/useDepartures'

const DepartureContext = createContext()

export const QUERY_PARAM_DEPARTURE_ID = 'departure_id'

const DepartureProvider = ({ children, departure: _departure }) => {
	const departures = useDepartures()
	const { push, query } = useRouter()

	const [departure, setDepartureState] = useState(() => {
		const defaultDepartureId = _departure ?? parseInt(query[QUERY_PARAM_DEPARTURE_ID])

		return departures?.length === 1
			? departures[0]
			: departures?.find(({ entity_id }) => entity_id === defaultDepartureId) ?? undefined
	})

	const setDeparture = useCallback(
		(departureId, url = undefined) => {
			const departure = departures?.find(({ entity_id }) => entity_id === departureId)
			if (departure) {
				setDepartureState(departure)
				push(
					{
						pathname: url ? url : window.location.pathname, // keep the same
						hash: window.location.hash, // keep it, if exists
						query: { [QUERY_PARAM_DEPARTURE_ID]: departureId },
					},
					null,
					{
						shallow: !url,
						silent: true,
					}
				)
			} else {
				console.error('Trying to set departure not compatible with current product')
			}
		},
		[departures, push]
	)

	const value = useMemo(() => [departure, setDeparture], [departure, setDeparture])

	return <DepartureContext.Provider value={value}>{children}</DepartureContext.Provider>
}

export default DepartureProvider

export const useDeparture = () => useContext(DepartureContext)
