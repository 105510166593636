import { useProductContext } from '@bluheadless/ui-logic/src/providers/product/product'

export const useDepartures = () => {
	const [{ product: { __rest: { departures = [] } = {} } = {} } = {}] = useProductContext() ?? []

	const currentDate = new Date(new Date().toDateString())

	const departuresFilter = departures.filter((departure) => {
		const saleableEndDate = new Date(departure.saleable_end_date)
		const departureDate = new Date(departure?.departure_date)
		return departureDate >= currentDate && saleableEndDate >= currentDate
	})

	return departuresFilter.map((departure) => {
		const saleableEndDate = new Date(departure?.saleable_end_date)

		if (saleableEndDate <= currentDate) {
			return { ...departure, is_salable: false, status: 2, group_status: 'closed' }
		}
		return departure
	})
}
